<template>
    <!-- Grid View with flex box and flex wrap -->
    <div class="container-grid-teams">
        <router-link to="/creativos" style="text-decoration: none;" class="teams-section-card creativos-card">
            <h2>Creativos</h2>
        </router-link>
        <router-link to="/mercadeo" style="text-decoration: none;" class="teams-section-card marketing-card">
            <h2>Mercadeo</h2>
        </router-link>
        <router-link to="/studio" style="text-decoration: none;" class="teams-section-card studio-card">
            <h2>Bestway Studio</h2>
        </router-link>
        <router-link to="/social-media" style="text-decoration: none;" class="teams-section-card social-card-mobile">
            <h2>Social Media</h2>
        </router-link>
        <router-link to="/tecnologias" style="text-decoration: none;" class="teams-section-card tech-card">
            <h2>Tecnologías</h2>
        </router-link>
        <router-link to="/social-media" class="social-card" style="z-index: 10;">
            <h2>Social Media</h2>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'TeamsHome'
}
</script>

<style>
    .container-grid-teams {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }
    .teams-section-card {
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .teams-section-card h2 {
        color: white;
    }
    /* Creativos Card */
    .creativos-card {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-creativos-banner_intro.jpeg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .creativos-card:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-creativos-banner_intro.jpeg');
    }
    /* Marketing Card */
    .marketing-card {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-mercadeo-banner_intro.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .marketing-card:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-mercadeo-banner_intro.jpg');
    }
    /* Studio Media Card */
    .studio-card {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('../../assets/img/BANNER BWS LARGE.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .studio-card:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url('../../assets/img/BANNER BWS LARGE.jpg');
    }
    /* Tech Card */
    .tech-card {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-tech-banner_intro.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .tech-card:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-tech-banner_intro.jpg');
    }
    /* Social */
    .social-card {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-social_media-banner_intro.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-decoration: none;
        border-radius: 50%;
        color: white;
        text-decoration: none;
        width: 35vh;
        height: 35vh;
        position: absolute;
        top: 0;
        bottom: 60px;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .social-card:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-social_media-banner_intro.jpg');
    }
    .social-card-mobile {
        display: none;
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('../../assets/img/team-social_media-banner_intro.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    @media screen and (max-width: 600px) {
        .teams-section-card {
            height: 25%;
            width: 100%;
        }
        .social-card {
            display: none;
        }
        .social-card-mobile {
            display: flex !important;
        }
    }
</style>
